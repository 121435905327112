import React from 'react'
import classnames from 'classnames'
import ProductTabsWidgetHeader from './productTabsWidgetHeader'
import ProductTabsWidgetContent from './productTabsWidgetContent'
import * as styles from './productTabsWidget.module.scss'

const ProductTabWidget = () => (
  <section className='col-centered'>
    <ProductTabsWidgetHeader styles={styles} />
    <ProductTabsWidgetContent styles={styles} />
  </section>
)

export default ProductTabWidget
