import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const HeroImage = ({ styles }) => (
  <div className={styles.containerHeroImage}>
    <div className={styles.wrapperImage}>
      <Image />
    </div>
  </div>
)

const Image = () => (
  <StaticQuery
    query={graphql`
      {
        placeholderImage: file(relativePath: { eq: "hero-maps.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={data => (
      <GatsbyImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        alt=''
      />
    )}
  />
)

export default HeroImage
