import React from 'react'
import classnames from 'classnames'

import alliance from '../../../images/l_team_alliance_colored.svg'
import astralis from '../../../images/l_team_astralis_colored.svg'
import echofox from '../../../images/l_team_echofox_colored.svg'
import excel from '../../../images/l_team_excel_colored.svg'
import hle from '../../../images/l_team_hle_colored.svg'
import mibr from '../../../images/l_team_mibr_colored.svg'
import mineski from '../../../images/l_team_mineski_colored.svg'
import navi from '../../../images/l_team_navi_colored.svg'
import origen from '../../../images/l_team_origen_colored.svg'
import sandbox from '../../../images/l_team_sandbox_colored.svg'
import splyce from '../../../images/l_team_splyce_colored.svg'

const HeroTeams = ({ styles }) => (
  <div className={classnames(styles.wrapperTeams, 'col-centered')}>
    <p>The world's best use Shadow</p>
    <img alt='team logo' src={astralis} />
    <img alt='team logo' src={navi} />
    <img alt='team logo' src={mibr} />
    <img alt='team logo' src={splyce} />
    <img alt='team logo' src={origen} />
    <img alt='team logo' src={echofox} />
    <img alt='team logo' src={excel} />
    <img alt='team logo' src={hle} />
    <img alt='team logo' src={sandbox} />
    <img alt='team logo' src={mineski} />
    <img alt='team logo' src={alliance} />
  </div>
)

export default HeroTeams
