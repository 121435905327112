import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const HeroImage = ({ styles, img }) => (
  <div className={styles.heroImage}>
    <div className={styles.image}>
      <Image img={img} />
    </div>
  </div>
)

const Image = ({ img }) => (
  <StaticQuery
    query={graphql`
      {
        navi: file(relativePath: { eq: "testimonial_simple@2x.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 900
              placeholder: NONE
              layout: CONSTRAINED
            )
          }
        }
        mibr: file(relativePath: { eq: "testimonial_fallen@2x.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 900
              placeholder: NONE
              layout: CONSTRAINED
            )
          }
        }
        astralis: file(relativePath: { eq: "testimonial_astralis@2x.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 900
              placeholder: NONE
              layout: CONSTRAINED
            )
          }
        }
      }
    `}
    render={data => {
      return <GatsbyImage image={data[img].childImageSharp.gatsbyImageData} />
    }}
  />
)

export default HeroImage
