import React from 'react'
import HeroCTA from './heroCta'
import HeroImage from './heroImage'
import HeroTeams from './heroTeams'
import * as styles from './hero.module.scss'

const Hero = () => (
  <section className={styles.hero}>
    <div className={['col-centered', styles.wrapperHero].join(' ')}>
      <div className={styles.containerHero}>
        <HeroCTA styles={styles} />
        <HeroImage styles={styles} />
      </div>
    </div>
    <HeroTeams styles={styles} />
  </section>
)

export default Hero
