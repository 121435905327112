import React, { Component } from 'react'
import classnames from 'classnames'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { GatsbyImage } from 'gatsby-plugin-image'
import LOL from '../../../images/svg/lol-icon.svg'
import CS from '../../../images/svg/cs-icon.svg'
import Dota from '../../../images/svg/dota-icon.svg'

class ProductTabsContent extends Component {
  componentDidMount() {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    })
    this.props.setTabsLoaded()
  }

  render() {
    const {
      styles,
      activeTab,
      setLolActive,
      setCsActive,
      setDotaActive,
    } = this.props

    return (
      <StaticQuery
        query={query}
        render={data => {
          const productTabs = [
            {
              title: 'Dota 2',
              className: activeTab === 'dota' && styles.active,
              onClick: setDotaActive,
              domId: 'dota',
              icon: Dota,
            },
            {
              title: 'Counter-Strike',
              className: activeTab === 'cs' && styles.active,
              onClick: setCsActive,
              domId: 'cs',
              icon: CS,
            },
            {
              title: 'League of Legends',
              className: activeTab === 'lol' && styles.active,
              onClick: setLolActive,
              domId: 'lol',
              icon: LOL,
            },
          ]

          const contentBlocks = {
            cs: [
              {
                img: data.cs_img1.childImageSharp.gatsbyImageData,
                h4Content: 'Cut through the noise',
                h3Content: 'Analyze Every Game, Faster',
                text:
                  'Our 2D replays and full 3D game rendering allow you to see every strat, every angle, every nade. Comprehensive game overviews let you jump to the most relevant rounds, and the most relevant moments. See what really matters.',
              },
              {
                img: data.cs_img2.childImageSharp.gatsbyImageData,
                h4Content: 'Expose tells and',
                h3Content: 'See Patterns Before Your Opponents Do',
                text:
                  'Find out just how your opponent plays a specific map and position, so you can punish their patterns and tells - even the ones they don’t know they have.',
              },
              {
                img: data.cs_img3.childImageSharp.gatsbyImageData,
                h4Content: 'Knowledge is power',
                h3Content: 'Harness the Most Powerful Data in Esports',
                text:
                  'Shadow holds the most data, and the best data on how teams play. Fully understand your strengths and weaknesses, and those of your opponents, using our data visualisations and veto analytics. ',
              },
              {
                img: data.cs_img4.childImageSharp.gatsbyImageData,
                h4Content: 'Waste no Time',
                h3Content: 'Build Reports & Share Them With Players',
                text:
                  'Shadow is designed to collect your findings into reports, that can easily be presented to players before key games - maximizing the throughput of relevant, game-changing information right when the players need it.',
              },
            ],
            lol: [
              {
                img: data.lol_img1.childImageSharp.gatsbyImageData,
                h4Content: 'Supercharge your',
                h3Content: 'Scrim Tracking',
                text:
                  'Shadow’s proprietary data collection technology puts scrim data at your fingertips, with easy-to-use interfaces, clear data visualizations, and deep statistics.',
              },
              {
                img: data.lol_img2.childImageSharp.gatsbyImageData,
                h4Content: 'Go beyond the eye test',
                h3Content: 'Upgrade Your VOD Review',
                text:
                  'Increase the efficiency of your match reviews and go way beyond the “eye test” with tools and visualizations that give context to what you’re seeing.',
              },
              {
                img: data.lol_img3.childImageSharp.gatsbyImageData,
                h4Content: 'More data, less wasted time',
                h3Content: 'Automate everything',
                text:
                  'We collect and organize comprehensive data from solo queue, scrims, and stage, so your staff can spend more of their time on analysis and application.',
              },
              {
                img: data.lol_img4.childImageSharp.gatsbyImageData,
                h4Content: 'Crush the competition',
                h3Content: 'Build your game plan',
                text:
                  'Break down every upcoming matchup with complete historical match information. Scout out and exploit your opponents’ tendencies in the draft and on the Rift.',
              },
            ],
            dota: [
              {
                img: data.dota_img1.childImageSharp.gatsbyImageData,
                h4Content: 'Own the meta',
                h3Content: 'Study the Leaderboard',
                text:
                  'Track smurfs and every leaderboard game. Know exactly who is playing what. Analyze counter picks using data from only the top 1000 ranks - data no one else has.',
              },
              {
                img: data.dota_img2.childImageSharp.gatsbyImageData,
                h4Content: 'Know your strengths & weaknesses',
                h3Content: 'Manage & Analyze Scrims',
                text:
                  'Upload, track, and manage your scrims on your own private server. Shadow provides you with powerful analytics into what drafts and playstyles make you win.',
              },
              {
                img: data.dota_img3.childImageSharp.gatsbyImageData,
                h4Content: 'See Everything',
                h3Content: 'All of the Data, The Best Data',
                text:
                  'Shadow contains every pro game ever played. See every ward, every smoke, every movement. Make use of powerful filters to understand how your opponents adapt to different game states.',
              },
              {
                img: data.dota_img4.childImageSharp.gatsbyImageData,
                h4Content: 'Outwit your opponent',
                h3Content: 'Master the Draft',
                text:
                  'Shadow uses historical drafting data to accurately model how certain teams and captains draft. Our draft tool allows you to practice drafts and tells you exactly how your selected opponent usually reacts to the picks and bans you have chosen.',
              },
            ],
          }

          return (
            <div className={styles.productTabsContent}>
              <div className={styles.tabs}>
                {productTabs.map((Tab, index) => (
                  <ProductTab
                    key={index}
                    styles={styles}
                    title={Tab.title}
                    className={Tab.className}
                    onClick={Tab.onClick}
                    domId={Tab.domId}
                  >
                    <Tab.icon />
                  </ProductTab>
                ))}
              </div>
              <div className={styles.content}>
                {activeTab in contentBlocks &&
                  contentBlocks[activeTab].map((i, index) => (
                    <ContentBlock
                      key={activeTab + index}
                      styles={styles}
                      img={i.img}
                      h4Content={i.h4Content}
                      h3Content={i.h3Content}
                      text={i.text}
                      dataAos={index % 2 ? 'fade-left' : 'fade-right'}
                    />
                  ))}
                {/*{activeTab === 'dota' && (*/}
                {/*  <div className={styles.buyBtnContainer}>*/}
                {/*    <a className='button' href='/DOTA_pricing'>*/}
                {/*      Buy Now*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*)}*/}
                {activeTab === 'cs' && (
                  <div className={styles.buyBtnContainer}>
                    <a className='button' href='/CSGO_pricing'>
                      Buy Now
                    </a>
                  </div>
                )}
              </div>
            </div>
          )
        }}
      />
    )
  }
}

const ProductTab = ({ styles, children, title, domId, className, onClick }) => (
  <div
    id={domId}
    className={classnames(styles.tab, className)}
    onClick={onClick}
  >
    {children}
    <h6>{title}</h6>
  </div>
)

const ContentBlock = ({ styles, img, h4Content, h3Content, text, dataAos }) => (
  <div className={styles.contentBlock} data-aos={dataAos}>
    <Col lg={7} md={6} sm={5} className={classnames('col-12')}>
      <Row className={styles.contentBlockImageWrapper}>
        <GatsbyImage image={img} className={styles.contentBlockImage} />
      </Row>
    </Col>
    <Col
      lg={1}
      md={1}
      className={classnames(styles.contentBlockSpacing, 'col-0')}
    >
      <Row />
    </Col>
    <Col
      lg={4}
      md={5}
      sm={6}
      className={classnames(styles.contentBlockText, 'col-12')}
    >
      <Row>
        <h4>{h4Content}</h4>
        <h3>{h3Content}</h3>
        <div className='custom-divider' />
        <p>{text}</p>
      </Row>
    </Col>
  </div>
)

const query = graphql`
  {
    cs_img1: file(relativePath: { eq: "product-tabs-csgo-1@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    cs_img2: file(relativePath: { eq: "product-tabs-csgo-2@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    cs_img3: file(relativePath: { eq: "product-tabs-csgo-3@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    cs_img4: file(relativePath: { eq: "product-tabs-csgo-4@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    lol_img1: file(relativePath: { eq: "product-tabs-lol-1@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    lol_img2: file(relativePath: { eq: "product-tabs-lol-2@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    lol_img3: file(relativePath: { eq: "product-tabs-lol-3@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    lol_img4: file(relativePath: { eq: "product-tabs-lol-4@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    dota_img1: file(relativePath: { eq: "product-tabs-dota-1@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    dota_img2: file(relativePath: { eq: "product-tabs-dota-2@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    dota_img3: file(relativePath: { eq: "product-tabs-dota-3@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    dota_img4: file(relativePath: { eq: "product-tabs-dota-4@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`

const mapStateToProps = ({ activeTab }) => {
  return { activeTab }
}

const mapDispatchToProps = dispatch => {
  return {
    setCsActive: () => dispatch({ type: `SET_CS_ACTIVE` }),
    setLolActive: () => dispatch({ type: `SET_LOL_ACTIVE` }),
    setDotaActive: () => dispatch({ type: `SET_DOTA_ACTIVE` }),
    setTabsLoaded: () => dispatch({ type: `TABS_LOADED` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTabsContent)
