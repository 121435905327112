import React from 'react'
import classnames from 'classnames'

const CarouselNavigation = ({ styles, items, active, setSlide }) => {
  return (
    <div className={styles.carouselNavigation}>
      {items.map((item, index) => (
        <div
          key={item.teamName + index}
          onClick={() => setSlide(index)}
          className={classnames(
            styles.carouselNavigationItem,
            index === active && styles.active
          )}
        >
          <img alt={item.teamName + ' logo'} src={item.logo} />
        </div>
      ))}
    </div>
  )
}

export default CarouselNavigation
