// extracted by mini-css-extract-plugin
export var active = "productTabs-module--active--Eg0hf";
export var buyBtnContainer = "productTabs-module--buyBtnContainer--rUH6z";
export var content = "productTabs-module--content--Szz1r";
export var contentBlock = "productTabs-module--contentBlock--RuCMI";
export var contentBlockImage = "productTabs-module--contentBlockImage--BEV7d";
export var contentBlockText = "productTabs-module--contentBlockText--6e3wd";
export var productTabsContent = "productTabs-module--productTabsContent--vuk4P";
export var productTabsHeader = "productTabs-module--productTabsHeader--LcLz5";
export var tab = "productTabs-module--tab--MYDcq";
export var tabs = "productTabs-module--tabs--0+eUH";