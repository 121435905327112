import React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/landing/hero/hero'
import Testimonial from '../components/landing/testimonial/testimonial'
import UspPartOne from '../components/landing/UspPartOne'
import ProductTabs from '../components/landing/productTabs/productTabs'
import ProductTabsWidget from '../components/landing/productTabsWidget/productTabsWidget'
import UspPartTwo from '../components/landing/UspPartTwo'
import TrialCTA from '../components/landing/trialCTA'
import CookieNotice from '../components/shared/CookieNotice'

const Index = ({ declarePageAsLanding }) => {
  declarePageAsLanding()
  return (
    <Layout>
      <SEO
        title='Shadow'
        description={`The leader in esports analytics for over 2 years, Shadow is used by the world’s best esports teams, in CS:GO, League of Legends and Dota 2 and has results to show for it.`}
        keywords={[
          'shadow',
          'esports',
          'analytics',
          'esports teams',
          'teams',
          'professional',
          'csgo',
          'cs:go',
          'dota2',
          'dota',
          'dota 2',
          'lol',
          'league of legends',
        ]}
      />
      <CookieNotice />
      <h1>
        Prepare like Champions, Perform like Champions | Game-changing esports
        analytics - Shadow
      </h1>
      <Hero />
      <Testimonial />
      <UspPartOne />
      <ProductTabsWidget />
      <ProductTabs />
      <UspPartTwo />
      <TrialCTA />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsLanding: () => dispatch({ type: `SET_LANDING_PAGE_TRUE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
