// extracted by mini-css-extract-plugin
export var active = "testimonial-module--active--EPNzg";
export var carouselNavigation = "testimonial-module--carouselNavigation--n+zTU";
export var carouselNavigationItem = "testimonial-module--carouselNavigationItem--ucfor";
export var container = "testimonial-module--container--JhjN1";
export var expand = "testimonial-module--expand--beMf7";
export var heroImage = "testimonial-module--heroImage--PRoEp";
export var image = "testimonial-module--image--I3HGG";
export var leftContainer = "testimonial-module--leftContainer--nTwss";
export var signature = "testimonial-module--signature--Nkkfv";
export var testimonial = "testimonial-module--testimonial--+xknP";
export var text = "testimonial-module--text--OVdnn";