import React, { Component } from 'react'
import classnames from 'classnames'
import Carousel from 'nuka-carousel'
import CarouselNavigation from './carouselNavigation'
import TestimonialText from './testimonialText'
import TestimonialImg from './testimonialImage'
import * as styles from './testimonial.module.scss'

import astralis from '../../../images/l_team_astralis_colored.svg'
import mibr from '../../../images/l_team_mibr_black.svg'
import navi from '../../../images/l_team_navi_colored.svg'

class Testimonial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slideIndex: 0,
    }

    this.setStateCallback = this.setStateCallback.bind(this)
  }

  setStateCallback(index) {
    this.setState({
      slideIndex: index,
    })
  }

  render() {
    const testimonials = [
      {
        testimonialText: [
          'We have come to rely on Shadow as a critical part of our preparation. With Shadow, we can review matches in full detail in a fraction of the time compared to in-game demo review, and the visualizations and analytics give us clear ideas about how to pick apart each opponent so we can keep reaching finals and winning titles. We use Shadow before tournaments, in the airport while we’re traveling, and even mid-event when we may only have a few hours between matches to create our game plans.',
        ],
        testimonialImg: 'navi',
        name: 'Aleksandr "s1mple" Kostyliev',
        logo: navi,
        teamName: 'NaVi',
        jobDescription: 'Player & 2018 World’s #1 by hltv.org',
      },
      {
        testimonialText: [
          'Shadow has become essential to our daily preparation. We can review and analyze games much faster and with far more depth. This allows us to find patterns in other teams’ behavior and decision-making that give us a competitive edge. We rely on Shadow for the insights and information we need to evolve our strategies as we adapt to meta changes and stay ahead of our opponents.',
        ],
        testimonialImg: 'mibr',
        name: 'Gabriel "FalleN" Toledo',
        logo: mibr,
        teamName: 'MIBR',
        jobDescription: 'Player & IGL',
      },
      {
        testimonialText: [
          'Astralis has topped the HLTV.org global rankings since early 2018, an incredible run of success that has seen them take home three of the last five Major Championships. In 2018 alone, Astralis won 9 premier tournaments. The team’s continued excellence is due not only to their talent, but also their renowned match preparation and analytical approach to the game. Shadow has been a proud part of Astralis’ arsenal since before their first Major title.',
        ],
        testimonialImg: 'astralis',
        logo: astralis,
        teamName: 'Astralis',
        jobDescription: '#1 team on HLTV.org global rankings since April 2018',
      },
    ]

    return (
      <section className={classnames(styles.testimonial, 'col-centered')}>
        <Carousel
          autoplay={true}
          heightMode='current'
          autoplayInterval={6000}
          speed={300}
          transitionMode='fade'
          wrapAround={true}
          withoutControls={true}
          dragging={false}
          pauseOnHover={false}
          slideIndex={this.state.slideIndex}
          afterSlide={slideIndex => this.setStateCallback(slideIndex)}
        >
          {testimonials.map((t, index) => (
            <div
              key={'slide_' + t.teamName}
              className={classnames('col-centered', styles.container)}
            >
              <div className={styles.leftContainer}>
                <TestimonialText
                  styles={styles}
                  testimonialText={t.testimonialText}
                  name={t.name}
                  logo={t.logo}
                  teamName={t.teamName}
                  jobDescription={t.jobDescription}
                  testimonials={testimonials}
                />
                <CarouselNavigation
                  styles={styles}
                  items={testimonials}
                  active={this.state.slideIndex}
                  setSlide={this.setStateCallback}
                />
              </div>
              <TestimonialImg styles={styles} img={t.testimonialImg} />
            </div>
          ))}
        </Carousel>
      </section>
    )
  }
}

export default Testimonial
