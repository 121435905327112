// extracted by mini-css-extract-plugin
export var active = "productTabsWidget-module--active--MbT-D";
export var buyBtnContainer = "productTabsWidget-module--buyBtnContainer--3zJh6";
export var content = "productTabsWidget-module--content--DZStY";
export var contentBlock = "productTabsWidget-module--contentBlock--eEP40";
export var contentBlockImage = "productTabsWidget-module--contentBlockImage--ZkD9b";
export var contentBlockText = "productTabsWidget-module--contentBlockText--2hEN2";
export var productTabsContent = "productTabsWidget-module--productTabsContent--yxm4G";
export var productTabsHeader = "productTabsWidget-module--productTabsHeader--cYOft";
export var tab = "productTabsWidget-module--tab--0sHdV";
export var tabs = "productTabsWidget-module--tabs--t-dJP";