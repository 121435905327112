import React from 'react'
import logo from '../../../images/shadow-logo-inverse.svg'

const ProductTabsHeader = ({ styles }) => (
  <div className={styles.productTabsHeader}>
    <h4>take your team to the next level</h4>
    <img src={logo} alt='Shadow' />
  </div>
)

export default ProductTabsHeader
