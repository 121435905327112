import React from 'react'
import classnames from 'classnames'
import ProductTabsHeader from './productTabsHeader'
import ProductTabsContent from './productTabsContent'
import * as styles from './productTabs.module.scss'

const ProductTabs = () => (
  <section className='col-centered'>
    <ProductTabsHeader styles={styles} />
    <ProductTabsContent styles={styles} />
  </section>
)

export default ProductTabs
