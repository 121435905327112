import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundImg from '../../shared/BackgroundImg'
import logo from '../../../images/shadow-logo.svg'
import * as styles from './styles.module.scss'

const TrialCTA = () => (
  <section className={styles.trialCTA}>
    <p>
      Unlock your <b>competitive advantage</b> now!
    </p>
    <div className={styles.wrapperImage}>
      <img alt='shadowgg logo' src={logo} />
    </div>
    <div className={styles.wrapperButton}>
      <a
        className='button'
        onClick={() => {
          window.LeadBooster.trigger('open')
        }}
      >
        Request a Demo Now →
      </a>
    </div>
    <StaticQuery
      query={graphql`
        {
          placeholderImage: file(relativePath: { eq: "bg_dota_cta@2x.png" }) {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => (
        <BackgroundImg
          image={data.placeholderImage.childImageSharp.gatsbyImageData}
        />
      )}
    />
  </section>
)

export default TrialCTA
