import React, { Component } from 'react'
import classnames from 'classnames'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { GatsbyImage } from 'gatsby-plugin-image'
import Widget from '../../../images/svg/widget-icon.svg'

class ProductTabsContent extends Component {
  componentDidMount() {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    })
    this.props.setWidgetTabsLoaded()
  }

  render() {
    const { styles, activeWidgetTab, setMediaActive } = this.props

    return (
      <StaticQuery
        query={query}
        render={data => {
          const productTabs = [
            {
              title: 'Media Widget',
              className: activeWidgetTab === 'media' && styles.active,
              onClick: setMediaActive,
              domId: 'media',
              icon: Widget,
            },
          ]

          const contentBlocks = {
            media: [
              {
                img: data.media_img1.childImageSharp.gatsbyImageData,
                h4Content: "See what's happening",
                h3Content: 'Comprehensive esports calendar',
                text:
                  'You are one JS-Snippet away from engaging your customers with a comprohensive esports calendar based on the BEDEX data portfolio.',
              },
              {
                img: data.media_img4.childImageSharp.gatsbyImageData,
                h4Content: 'High level stats',
                h3Content: 'Easy high level overview',
                text:
                  'Get a quick and easy overview of how a match is going with team stats and a live timeline showing you the most important events that are happening right now. ',
              },
              {
                img: data.media_img2.childImageSharp.gatsbyImageData,
                h4Content: 'Never seen before granularity',
                h3Content: 'Follow each second of the game',
                text:
                  'See every movement and every play made by your favourite players in realtime on our 2D live map and keep track of key events in the event timeline.',
              },
              {
                img: data.media_img3.childImageSharp.gatsbyImageData,
                h4Content: 'Historical data',
                h3Content: 'In depth matchup stats',
                text:
                  'Get a detailed performace overview of teams, before, after and during a match. See their previous results against each other and compare their performance.',
              },
            ],
          }

          return (
            <div className={styles.productTabsContent}>
              <div className={styles.tabs}>
                {productTabs.map((Tab, index) => (
                  <ProductTab
                    key={index}
                    styles={styles}
                    title={Tab.title}
                    className={Tab.className}
                    onClick={Tab.onClick}
                    domId={Tab.domId}
                  >
                    <Tab.icon />
                  </ProductTab>
                ))}
              </div>
              <div className={styles.content}>
                {activeWidgetTab in contentBlocks &&
                  contentBlocks[activeWidgetTab].map((i, index) => (
                    <ContentBlock
                      key={activeWidgetTab + index}
                      styles={styles}
                      img={i.img}
                      h4Content={i.h4Content}
                      h3Content={i.h3Content}
                      text={i.text}
                      dataAos={index % 2 ? 'fade-left' : 'fade-right'}
                    />
                  ))}
                {activeWidgetTab === 'media' && (
                  <div className={styles.buyBtnContainer}>
                    <a
                      className='button'
                      onClick={() => {
                        window.LeadBooster.trigger('open')
                      }}
                    >
                      Get in Touch
                    </a>
                  </div>
                )}
              </div>
            </div>
          )
        }}
      />
    )
  }
}

const ProductTab = ({ styles, children, title, domId, className, onClick }) => (
  <div
    id={domId}
    className={classnames(styles.tab, className)}
    onClick={onClick}
  >
    {children}
    <h6>{title}</h6>
  </div>
)

const ContentBlock = ({ styles, img, h4Content, h3Content, text, dataAos }) => (
  <div className={styles.contentBlock} data-aos={dataAos}>
    <Col lg={7} md={6} sm={5} className={classnames('col-12')}>
      <Row className={styles.contentBlockImageWrapper}>
        <GatsbyImage image={img} className={styles.contentBlockImage} />
      </Row>
    </Col>
    <Col
      lg={1}
      md={1}
      className={classnames(styles.contentBlockSpacing, 'col-0')}
    >
      <Row />
    </Col>
    <Col
      lg={4}
      md={5}
      sm={6}
      className={classnames(styles.contentBlockText, 'col-12')}
    >
      <Row>
        <h4>{h4Content}</h4>
        <h3>{h3Content}</h3>
        <div className='custom-divider' />
        <p>{text}</p>
      </Row>
    </Col>
  </div>
)

const query = graphql`
  {
    media_img1: file(relativePath: { eq: "calendar_dark.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    media_img2: file(relativePath: { eq: "csgo_live.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    media_img3: file(relativePath: { eq: "dota_postmatch.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    media_img4: file(relativePath: { eq: "dota_live.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 900
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`

const mapStateToProps = ({ activeWidgetTab }) => {
  return { activeWidgetTab }
}

const mapDispatchToProps = dispatch => {
  return {
    setMediaActive: () => dispatch({ type: `SET_MEDIA_ACTIVE` }),
    setWidgetTabsLoaded: () => dispatch({ type: `WIDGET_TABS_LOADED` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTabsContent)
