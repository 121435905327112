import React from 'react'
import Usp from '../shared/Usp'
import matches from '../../../images/matches.svg'
import essential from '../../../images/essential.svg'
import dashboard from '../../../images/dashboard.svg'
import * as styles from './styles.module.scss'

const UspPartOne = () => {
  const usps = [
    {
      icon: matches,
      title: 'Client Success',
      text:
        'Shadow clients won 50% of the premier competitions from 2018 - 2021 in CS:GO, Dota 2, and LoL.',
    },
    {
      icon: dashboard,
      title: 'The Essential Platform',
      text:
        'More than 20 top-tier professional teams and organizations from around the world rely on Shadow every day.',
    },
    {
      icon: essential,
      title: 'Paving the Way',
      text:
        'Shadow has been the global leader in professional esports analytics since 2016 and is expanding into Media and Audience engagement.',
    },
  ]
  return (
    <section className={styles.section}>
      <div className='col-centered'>
        <div className={styles.container}>
          <div className={styles.item}>
            <h4>We’re proud of our</h4>
            <h2>Proven record</h2>
            <p className={styles.text}>
              Shadow is used by the world’s best esports companies, and has
              results to show for it.
            </p>
          </div>
          {usps.map((i, index) => (
            <Usp
              className={styles.item}
              textClassName={styles.uspText}
              icon={i.icon}
              title={i.title}
              text={i.text}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default UspPartOne
