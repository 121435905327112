import React from 'react'

const HeroCTA = ({ styles }) => (
  <div className={styles.containerHeroCta}>
    <h2 className='text-color--white'>Prepare like Champions.</h2>
    <h2 className='text-color--primary'>Perform like Champions.</h2>
    <div className={styles.subline}>
      Enter every match with an advantage, powered by Shadow’s game-changing
      esports analytics. From Esports Coaches to Media Outlets, we deliver
      unique, easy to grasp insights.
    </div>
    <div className={styles.wrapperHeroCTA}>
      <a
        className='button'
        onClick={() => {
          window.LeadBooster.trigger('open')
        }}
      >
        Get in Touch
      </a>
    </div>
  </div>
)

export default HeroCTA
