import React from 'react'
import classnames from 'classnames'
import Usp from '../shared/Usp'
import smiley from '../../../images/chat-happy.svg'
import lightbulb from '../../../images/light-bulb.svg'
import lock from '../../../images/lock.svg'
import binoculars from '../../../images/binoculars.svg'
import * as styles from './styles.module.scss'

const UspPartOne = () => {
  const usps = [
    {
      icon: smiley,
      title: 'Client Support',
      text:
        'Our dedicated client support staff is located across multiple continents, so we’re available around the clock to answer your questions and handle any requests.',
    },
    {
      icon: lightbulb,
      title: 'Constantly Upgrading',
      text:
        'Shadow releases new features and improvements on a weekly basis, and we work closely with our clients to get in-depth feedback and to figure out what to build next.',
    },
    {
      icon: lock,
      title: 'Data Security',
      text:
        'Trust Shadow’s comprehensive security measures to keep your accounts protected and your data and reports completely private, accessible only to you and your team. We use proven security technologies like Google OAuth and Steam to keep your data safe.',
    },
    {
      icon: binoculars,
      title: 'Long-Term Stability',
      text:
        'We are a growing team within a large, well-funded esports tech company that’s been around since 2016. We’re here to stay.',
    },
  ]
  return (
    <section className={styles.section}>
      <div className={classnames('col-centered', styles.container)}>
        <div className={styles.header}>
          <h4>We’ve got you covered</h4>
          <h3>Shadow is your Reliable, Long-term Solution</h3>
        </div>
        <div className={styles.uspWrapper}>
          {usps.map((i, index) => (
            <Usp
              className={styles.item}
              icon={i.icon}
              title={i.title}
              text={i.text}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default UspPartOne
