import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

const Usp = ({
  className,
  imgClassName,
  titleClassName,
  textClassName,
  icon,
  title,
  text,
}) => (
  <div className={classnames(styles.usp, className)}>
    <img
      className={classnames(styles.img, imgClassName)}
      alt={icon}
      src={icon}
    />
    <h5 className={classnames(styles.title, titleClassName)}>{title}</h5>
    <p className={classnames(styles.text, textClassName)}>{text}</p>
  </div>
)

export default Usp
