import React from 'react'

const TestimonialText = ({
  styles,
  testimonialText,
  name,
  logo,
  teamName,
  jobDescription,
}) => (
  <div className={styles.text}>
    <h4>The best teams in the world</h4>
    <h3>Rely on Shadow</h3>
    <div className='custom-divider' />
    {testimonialText.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ))}
    <div className={styles.signature}>
      {name && <p>{name}</p>}
      <p>
        <img
          alt={teamName + ' logo'}
          src={logo}
          style={{ marginRight: '16px' }}
        />
        <span>
          {teamName + ' '}
          <span style={{ fontWeight: 'normal' }}>{jobDescription}</span>
        </span>
      </p>
    </div>
  </div>
)

export default TestimonialText
